import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import './App.css';
import Controls from './components/Controls/Controls';
import EndConsult from './components/Dialogs/EndConsult/EndConsult';
import LocalVideoPreview from './components/LocalVideoPreview/LocalVideoPreview';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Redirecting from './components/Redirecting/Redirecting';
import Room from './components/Room/Room';
import { useAppState } from './state';
import useRoomState from './hooks/useRoomState/useRoomState';
import ConnectBar from './components/MenuBar/connectBar/ConnectBar';
import Chat from './components/chat/chat';
//import useSocketConnection from './hooks/useSocketConnection/useSocketConnection';
import { WebSocketContext } from './components/socketProvider/socketProvider';
import SettingDialog from './components/Dialogs/Settings/SettingDialog';
import { useSelector, useDispatch } from 'react-redux';
import { closeToast } from './store/Actions/Actions';
import SimpleReactLightbox from 'simple-react-lightbox';
import ConsultEnded from './components/ConsultEnded/ConsultEnded';
const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
});

const Main = styled('main')({
  height: '100%',
  position: 'relative',
  display: 'flex',
});

const VideoContainer = styled('div')({
  height: '100%',
  width: '100%',
  position: 'relative',
});

export default function App() {
  const { user, getToken, isFetching } = useAppState();
  const roomState = useRoomState();
  const { socket, users, incall } = useSelector(state => state.room);
  const toast = useSelector(state => state.toast);
  const chatopen = useSelector(state => state.room.chatopen);
  const consult_ended = useSelector(state => state.room.consult_ended);
  const dispatch = useDispatch();
  const [toastType, setToastType] = useState({
    vertical: 'top' as 'top',
    horizontal: 'center' as 'center',
  });
  const { vertical, horizontal } = toastType;
  const ws = useContext(WebSocketContext);
  //const { isJoined, JoinUser, sendMessage } = useSocketConnection();
  useEffect(() => {
    if ((user != undefined || user != null) && user.access_code && !ws.isJoined && ws.socket) {
      setTimeout(() => {
        if (socket) {
          ws?.JoinUser(user.room, user.access_code);
        }
      }, 2000);
    } else {
      console.log('already joined ');
    }
  }, [user, socket]);

  const closeSnackbar = () => {
    dispatch(closeToast());
  };

  const chatClass = chatopen ? 'isopen' : '';
  return (
    <>
      {user?.is_host.toString() == '0' &&
        !incall &&
        users.filter(user => {
          return user.is_active === 1;
        }).length <= 1 && (
          <div className="waiting_modal">
            <div className="modal_content">
              <p>Please wait while the healthcare provider joins.</p>
              <p>You will be notified once they join.</p>
            </div>
          </div>
        )}
      {consult_ended && user?.is_host.toString() == '0' && <ConsultEnded />}
      {!consult_ended && (
        <Container>
          <SimpleReactLightbox>
            <div className="videoContainer">
              <ConnectBar />
              <SettingDialog />
              <EndConsult />
              <Main>
                {roomState === 'disconnected' ? <LocalVideoPreview /> : <Room />}
                <Controls />
              </Main>
            </div>
            <div className={'chatContainer ' + chatClass}>
              <Chat />
            </div>
            <ReconnectingNotification />
            <Redirecting />
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              key={`${vertical},${horizontal}`}
              open={toast.isOpen}
              message={toast.message}
              autoHideDuration={3000}
              onClose={closeSnackbar}
            />
          </SimpleReactLightbox>
        </Container>
      )}
    </>
  );
}
