import React, { useState } from 'react';
import ParticipantStrip from '../ParticipantStrip/ParticipantStrip';
import GridParticipantStrip from '../ParticipantStrip/GridParticipantStrip';
import { styled } from '@material-ui/core/styles';
import MainParticipant from '../MainParticipant/MainParticipant';
import useParticipants from '../../hooks/useParticipants/useParticipants';
const Container = styled('div')({
  position: 'relative',
  height: '100%',
  display: 'flex',
  flex: 1,
});

const MainParticipantContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  '& > div': {
    height: '100%',
  },
}));

export default function Room() {
  const [isGrid, setIsGrid] = useState(false);

  return (
    <Container>
      <div>
        <ParticipantStrip />
        <MainParticipantContainer>
          <MainParticipant />
        </MainParticipantContainer>
      </div>
    </Container>
  );
}
