import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useAppState } from '../../state';
import { withRouter, RouteComponentProps } from 'react-router';
export default function PrivateRoute({ children, ...rest }: RouteProps) {
  const { isAuthReady, user } = useAppState();
  const renderChildren = user;
  if (!renderChildren && !isAuthReady) {
    return null;
  }
  console.log('im');
  return (
    <Route
      {...rest}
      render={({ location }) =>
        renderChildren ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
