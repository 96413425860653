import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAppState } from '../../state';
import { api } from '../../_helpers/api';
export default function useApiCall() {
  const { user } = useAppState();

  const { toast } = useSelector(state => state);
  const dispatch = useDispatch();

  const updateAppStatus = status => {
    fetch(api.API_URL + `consultation/update`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: 'Bearer ' + user?.authToken,
      },
      body: JSON.stringify({
        status: status,
        appointment_id: user?.appointment_id,
      }),
    })
      .then(response => response.json())
      .then(res => {
        console.log('app status updated');
      });
  };

  return { updateAppStatus };
}
