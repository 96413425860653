import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import IconButton from '@material-ui/core/IconButton';
import CallEnd from '@material-ui/icons/CallEnd';
import Message from '@material-ui/icons/Message';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import useFullScreenToggle from '../../../hooks/useFullScreenToggle/useFullScreenToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);

export default function FullScreen() {
  const classes = useStyles();
  const { room } = useVideoContext();
  const [isFullScreen, toggleFullScreen] = useFullScreenToggle();
  return (
    <Tooltip title={'Full Screen Mode'} placement="top">
      <div className="UseCall" onClick={toggleFullScreen}>
        <div className="call_act_button">{isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}</div>
      </div>
    </Tooltip>
  );
}
