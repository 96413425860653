import React, { createContext, useState } from 'react';
import io from 'socket.io-client';
import { api } from '../../_helpers/api';
import { createToast } from '../../store/Actions/Actions';
import { useSelector, useDispatch } from 'react-redux';

export interface ISocketContext {
  socket: any;
  JoinUser: any;
  sendMessage: any;
  redirectUser: any;
  endConsultation: any;
  disconnectMe: any;
  isJoined: any;
}
export const WebSocketContext = createContext<ISocketContext>(null!);
export default ({ children }) => {
  let ws;
  let socket = useSelector(state => state.room.socket);
  const [isJoined, setIsJoined] = useState(false);
  const [chatIsOpen, SetChatIsOpen] = useState(false);
  const dispatch = useDispatch();

  const createUnread = () => {
    if (!chatIsOpen) {
      dispatch({
        type: 'UNREAD_MESSAGE',
      });
    }
  };

  const twilioDisconnect = () => {
    // @ts-ignore
    window.twilioRoom.disconnect();
  }

  const disconnectMe = () => {
    if(socket) socket.emit('userleft');

    dispatch({
      type: 'CONSULT_ENDED',
      consult_ended: true,
    });
    // @ts-ignore
    window.twilioRoom.disconnect();
  };
  const JoinUser = (room_hash, access_code) => {
    console.log(socket);
    if (socket) {
      socket.emit('join', { room_hash, access_code }, error => {
        if (error) {
          alert(error);
        } else {
          setIsJoined(true);
          dispatch({
            type: 'CONNECTED_TO_SOCKET',
            socketconnected: true,
          });
        }
      });
    } else {
      console.log('socket not connected yet');
    }
  };

  const sendMessage = (room_hash, message_data) => {
    if (message_data) {
      socket.emit('sendMessage', { room_hash, message_data });
    }
  };

  const redirectUser = room_hash => {
    socket.emit('redirectUser', { room_hash});
  };

  const endConsultation = room_hash => {
    console.log('end call called ' + room_hash);
    socket.emit('endConsultation', { room_hash });
    dispatch({
      type: 'ASK_PRE_END_CALL',
      isEndCall: false,
    });
  };

  if (!socket) {
    socket = io(api.SOCKET_URL);
    dispatch({
      type: 'SET_SOCKET',
      socket,
    });
    console.log('connecting...');
    /**
     * Registering Message recievers
     */
    socket.on('message', message => {
      //  setMessages(messages => [ ...messages, message ]);
      dispatch({
        type: 'ADD_MESSAGE',
        message: message,
      });

      createUnread();

      dispatch(createToast('You have new message'));
    });

    socket.on('userin', ({ user, message }) => {
      dispatch({
        type: 'SET_ONLINE',
        user: user,
      });
      if (user.is_host == 1) {
        dispatch({
          type: 'SET_REDIRECTING',
          isredirecting: false,
        });
      }
      dispatch(createToast(message));
    });

    socket.on('userleave', ({ user, message }) => {
      dispatch({
        type: 'SET_OFFLINE',
        user: user,
      });
      dispatch(createToast(message));
    });

    socket.on('roomData', ({ room, users, messages }) => {
      dispatch({
        type: 'SET_ROOM_DATA',
        messages: messages,
        users: users,
      });
    });

    socket.on('hostEnded', ({ room_data }) => {
      dispatch(createToast('Host ended the call'));
      disconnectMe();
    });

    socket.on('redirectUser', ({ room_data }) => {
      dispatch({
        type: 'SET_REDIRECTING',
        isredirecting: true,
      });
      socket.emit('getFreshData',{room_hash:room_data});
      setTimeout(() => {
        twilioDisconnect();
      }, 1000);
      //  dispatch(createToast('Please wait while you are redirected'));
    });
  }

  ws = {
    socket: socket,
    JoinUser,
    sendMessage,
    redirectUser,
    endConsultation,
    disconnectMe,
    isJoined,
  };

  return <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>;
};
