import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import { styled } from '@material-ui/core/styles';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import DeleteIcon from '@material-ui/icons/Delete';

import useChat from '../../../hooks/useChat/useChat';
import { select } from 'glamor';
export default function ChatInput() {
  const { createMessage, sendFile } = useChat();

  const [messageinput, SetMessageInput] = useState('');
  const [selectedFile, setSelectedFile] = useState<any>();
  const inputFile = useRef<any>(null);

  const handleMessageInput = event => {
    SetMessageInput(event.target.value);
  };

  const handleFileInput = event => {
    console.log(event.target.files[0]);
    setSelectedFile(event.target.files[0]);
  };

  const _handleKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      submitMessage();
      SetMessageInput('');
      e.preventDefault();
    }
  };

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    if (inputFile) {
      inputFile.current.click();
    }
  };

  const clearFile = () => {
    setSelectedFile(null);
  };
  const submitMessage = () => {
    if (messageinput) {
      createMessage(messageinput);
      SetMessageInput('');
    }

    if (selectedFile) {
      sendFile(selectedFile);
      setSelectedFile(null);
    }
  };

  return (
    <>
      <div className="chatinput">
        <div className="enter">
          {!selectedFile && (
            <div className="enter__textarea">
              <textarea
                name="enterMessage"
                id="enterMessage"
                value={messageinput}
                onChange={handleMessageInput}
                onKeyDown={_handleKeyDown}
                placeholder="Say message..."
              ></textarea>
            </div>
          )}
          {selectedFile && (
            <div className="enter__textarea">
              <div className="selectedfile">
                <div className="name">{selectedFile?.name}</div>
                <div className="action" onClick={clearFile}>
                  <DeleteIcon />
                </div>
              </div>
            </div>
          )}
          <div className="enter__icons">
            {!messageinput && (
              <a href="#" onClick={onButtonClick} className="enter__icon">
                <AttachFileRoundedIcon />
                <input type="file" className="chatfileinput" ref={inputFile} name="file" onChange={handleFileInput} />
              </a>
            )}
            <a href="#" onClick={submitMessage} className="enter__icon right">
              <SendRoundedIcon />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
