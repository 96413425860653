import React, { useState, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import { useSelector, useDispatch } from 'react-redux';
import { useAppState } from '../../../state';
import ChatInput from './ChatInput';
import { css } from 'glamor';
import { api } from '../../../_helpers/api';
import ScrollToBottom from 'react-scroll-to-bottom';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import Linkify from 'react-linkify';
import { SRLWrapper } from 'simple-react-lightbox';
const ROOT_CSS = css({
  height: '100%',
  width: '100%',
});

const myMessage = css({
  color: '#a30',
});

const LinkDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank">
    {text}
  </a>
);

const ImageMessage = props => {
  const anchorClick = e => {
    // function upvote
    e.preventDefault();
    console.log('no default triggered');
    return false;
  };

  return (
    <div className="image">
      <a href={api.SYSTEM_API + '/upload/message_orig/' + props.image_name} onClick={anchorClick} data-attribute="SRL">
        <img src={api.SYSTEM_API + '/upload/message/' + props.image_name} alt="" />
      </a>
    </div>
  );
};

const DocMessage = props => {
  return (
    <div className="doc">
      <div className="saveicon">
        <ArrowDownwardOutlinedIcon />
      </div>
      <div className="details">
        <div className="docname">{props.doc_name}</div>
        <div className="action">
          <ul>
            <li>
              <a href="#" onClick={() => window.open(api.SYSTEM_API + 'documentsave?doc=' + props.doc_name, '_blank')}>
                Download
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
let messageUser;
let messagesNumber;
const MessageBox = props => {
  const { user, getToken, isFetching } = useAppState();

  if (props.message.id === null) {
    return <></>;
  }

  let scratchStart = messageUser != props.message.fromuser;

  if (typeof messageUser == 'undefined' || messageUser != props.message.fromuser) {
    messageUser = props.message.fromuser;
    messagesNumber = 0;
  }
  const startClass = scratchStart ? 'start' : '';
  const isMine = user?.user_id == props.message.fromuser ? 'mine' : '';
  const isFile = props.message.photo != '' ? 'imagemessage' : '';
  return (
    <div key={props.message.id} className={'message ' + startClass + ' ' + isMine + ' ' + isFile}>
      {scratchStart && (
        <div className="authordet">
          {
            props.users.find(
              user => user.user_id === props.message.fromuser && user.user_type === props.message.fromuser_type
            )?.display_name
          }
        </div>
      )}
      <div className="messagecontent">
        {props.message.message && <Linkify componentDecorator={LinkDecorator}>{props.message.message}</Linkify>}
        {props.message.photo && props.message.file_data?.type == 'image' && (
          <ImageMessage key={props.message.id} image_name={props.message.photo} />
        )}
        {props.message.photo && (!props.message.file_data || props.message.file_data?.type != 'image') && (
          <DocMessage key={props.message.id} doc_name={props.message.photo} />
        )}
      </div>
    </div>
  );
};

const PendingMessageBox = props => {
  const { user, getToken, isFetching } = useAppState();

  const startClass = 'start';
  const isMine = 'mine';
  const isFile = '';
  return (
    <div key={props.index} className={'message ' + startClass + ' ' + isMine + ' ' + isFile}>
      <div className="messagecontent">Sending message...</div>
    </div>
  );
};

export default function ChatBox() {
  const [isGrid, setIsGrid] = useState(false);
  const { user, getToken, isFetching } = useAppState();
  const { messages, users, pending_messages } = useSelector(state => state.room);
  const [currentuser, SetCurrentuser] = useState('');
  const [lastMessageUser, setLastMessageUser] = useState('');

  let rows = [];
  for (var i = 0; i < pending_messages; i++) {
    // note: we add a key prop here to allow react to uniquely identify each
    // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
    // @ts-ignore
    rows.push(<PendingMessageBox key={i} />);
  }

  return (
    <div className="chatBox">
      <div className="messagescontainer">
        <ScrollToBottom className={'msgbox ' + ROOT_CSS}>
          <SRLWrapper>
            {messages.length > 0 ? (
              messages.map(message => (
                <MessageBox
                  key={message.id}
                  message={message}
                  users={users}
                  lastMessageUser={lastMessageUser}
                  setLastMessageUser={setLastMessageUser}
                />
              ))
            ) : (
              <div></div>
            )}
          </SRLWrapper>
          {rows}
        </ScrollToBottom>
      </div>
      <ChatInput />
    </div>
  );
}
