import React from 'react';
import useTrack from '../../hooks/useTrack/useTrack';
import AudioTrack from '../AudioTrack/AudioTrack';
import VideoTrack from '../VideoTrack/VideoTrack';
import Person from '@material-ui/icons/Person';
import { IVideoTrack } from '../../types';
import {
  AudioTrack as IAudioTrack,
  LocalTrackPublication,
  Participant,
  RemoteTrackPublication,
  Track,
} from 'twilio-video';

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  participant: Participant;
  isLocal: boolean;
  disableAudio?: boolean;
  videoPriority?: Track.Priority | null;
  imagesize: string;
}
export function DefaultRenderLocal() {
  return (
    <div className="participant_off">
      <Person />
    </div>
  );
}
export default function Publication({
  publication,
  isLocal,
  disableAudio,
  videoPriority,
  imagesize,
}: PublicationProps) {
  const track = useTrack(publication);
  if (!track) return <div className="persondefault"></div>;

  let publicationType;
  if (publication.trackName.toLowerCase().includes('screen')) {
    publicationType = 'screen';
  } else {
    publicationType = 'camera';
  }

  switch (track.kind) {
    case 'video':
      return (
        <VideoTrack
          track={track as IVideoTrack}
          priority={videoPriority}
          isLocal={track.name.includes('camera') && isLocal}
          imagesize={imagesize}
          publicationType={publicationType}
        />
      );
    case 'audio':
      return disableAudio ? null : <AudioTrack track={track as IAudioTrack} />;
    default:
      return null;
  }
}
