import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import InfoIcon from '@material-ui/icons/Info';
import Snackbar from '@material-ui/core/Snackbar';
import { SnackbarContent } from '@material-ui/core';

import useRoomState from '../../hooks/useRoomState/useRoomState';
import { useSelector } from 'react-redux';
const useStyles = makeStyles({
  snackbar: {
    backgroundColor: '#6db1ff',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '0.8em',
  },
});

export default function Redirecting() {
  const classes = useStyles();
  const roomState = useRoomState();
  const { isredirecting } = useSelector(state => state.room);
  console.log('being redirect: ' + isredirecting);
  return (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={isredirecting}>
      <SnackbarContent
        className={classes.snackbar}
        message={
          <span className={classes.message}>
            <InfoIcon className={classes.icon} />
            You are being Redirected&hellip;<br></br>
            الرجاء الانتظار بينما يتم تحويلكم
          </span>
        }
      />
    </Snackbar>
  );
}
