import React, { useContext, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Logout from '../../Controls/logout/logout';
import Toolbar from '@material-ui/core/Toolbar';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import { useAppState } from '../../../state';
import useRoomState from '../../../hooks/useRoomState/useRoomState';
//import useSocketConnection from '../../../hooks/useSocketConnection/useSocketConnection';
import { WebSocketContext } from '../../../components/socketProvider/socketProvider';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useTimer from '../../../hooks/useTimer/useTimer';
import { useSelector, useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { DeviceSelector } from '../DeviceSelector/DeviceSelector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      paddingTop: '20px',
    },
    form: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    displayName: {
      marginLeft: '2.2em',
      minWidth: '200px',
      fontWeight: 600,
    },
    startButton: {
      borderRadius: '30px',
      color: '#fff',
      '&:hover': {
        background: '#0393de',
      },
      '&:disabled': {
        background: '#ccc',
      },
    },
  })
);
export function BrandLogo() {
  return <div className="brandlg"></div>;
}

export default function ConnectBar() {
  const classes = useStyles();
  const { user, getToken, isFetching } = useAppState();
  const ws = useContext(WebSocketContext);
  const { getFormat, setTimer, clearTimer, currentTime, currentTimeString } = useTimer();
  const { isConnecting, connect } = useVideoContext();
  const { users, incall, audioloaded, medialoaded, main_speaker, connecting } = useSelector(state => state.room);
  const roomState = useRoomState();
  const room = window.sessionStorage.getItem('room');
  const username = window.sessionStorage.getItem('user');
  const [access, setAccess] = useState<string>(user?.username || '');
  const [roomId, setRoomId] = useState<string>(user?.room || '');
  const [totaltime, setTotaltime] = useState(0);
  const [currentOnline, setCurrentOnline] = useState<any>([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (room) {
      setRoomId(room);
    }
    if (user) {
      setTotaltime(user?.total_time);
    }
    setCurrentOnline(
      users.filter(user => {
        return user.is_active === 1;
      })
    );

    if (
      users.filter(user => {
        return user.is_active === 1;
      }).length > 1 &&
      audioloaded &&
      medialoaded &&
      !incall &&
      !connecting
    ) {
      reduxConnect();
    }
  }, [user, room, users, audioloaded, medialoaded]);

  const reduxConnect = () => {
    const token = window.sessionStorage.getItem('token');
    if (token && audioloaded && medialoaded) {
      connect(token);
      setTimer(totaltime);
    }
  };

  const connectCall = () => {
    const token = window.sessionStorage.getItem('token');
    if (token && !incall) {
      connect(token);
      setTimer(totaltime);
    }
  };
  return (
    <>
      {roomState === 'disconnected' ? (
        <AppBar
          className={classes.container + (roomState !== 'disconnected' ? ' hidden' : ' show')}
          position="absolute"
        >
          <Toolbar>
            <BrandLogo />
            {roomState === 'disconnected' ? (
              <div className={classes.form}>
                <DeviceSelector />
                <Logout />
                <Tooltip
                  title={currentOnline.length <= 1 ? 'Waiting for others to join' : 'Connect to video now'}
                  placement="top"
                >
                  <span>
                    <Button
                      className={'callstartbutton ' + classes.startButton}
                      type="submit"
                      color="primary"
                      variant="contained"
                      onClick={connectCall}
                      disabled={!user?.token || currentOnline.length <= 1 || !ws?.socket.connected}
                    >
                      <PhoneRoundedIcon />
                      &nbsp;<span className="txt">Start Call</span>
                    </Button>
                  </span>
                </Tooltip>
                {(isConnecting || isFetching) && <CircularProgress className={classes.loadingSpinner} />}
              </div>
            ) : (
              <div className={classes.form}></div>
            )}
          </Toolbar>
        </AppBar>
      ) : (
        <AppBar className={classes.container} position="absolute">
          <Toolbar>
            <BrandLogo />
            <div className={'speaker_identity'}>
              <span>{main_speaker.identity}</span>
            </div>
            <div className={'timer ' + (currentTime > totaltime ? ' over' : '')}>
              <span>{currentTimeString}</span>
            </div>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
}
