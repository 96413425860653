import React from 'react';
import ParticipantInfoNoVideo from '../ParticipantInfo/ParticipantInfoNoVideo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import { LocalParticipant, RemoteParticipant } from 'twilio-video';

interface ParticipantProps {
  participant: LocalParticipant | RemoteParticipant;
  disableAudio?: boolean;
  enableScreenShare?: boolean;
  onClick: () => void;
  isSelected: boolean;
}

export default function ParticipantNoVideo({
  participant,
  disableAudio,
  enableScreenShare,
  onClick,
  isSelected,
}: ParticipantProps) {
  return (
    <ParticipantInfoNoVideo participant={participant} onClick={onClick} isSelected={isSelected}>
      <ParticipantTracks
        participant={participant}
        disableAudio={disableAudio}
        enableScreenShare={enableScreenShare}
        imagesize="small"
      />
    </ParticipantInfoNoVideo>
  );
}
