import React, { createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TwilioError } from 'twilio-video';
import useTokenAuth from './useTokenAuth/useTokenAuth';
export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  getToken(room: any, name: any): Promise<string>;
  checkLogin(roomID: any, accessCode: any): Promise<boolean>;
  user?: {
    username: string;
    room: string;
    token: string;
    room_type: string;
    consult_id: string;
    access_code: string;
    is_host: number;
    user_id: string;
    appointment_id: string;
    authToken: string;
    user_type: string;
    start_date: string;
    total_time: number;
  } | null;
  signIn?(consult_id?: string, access_code?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  isLoggedIn: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks fron being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/

export function getUrlToken() {
  const match = window.location.search.match(/token=(.*)&?/);
  const token = match ? match[1] : window.sessionStorage.getItem('token');
  return token;
}

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [useUrl, setUseUrl] = useState(true);
  const [activeSinkId, setActiveSinkId] = useState('default');

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
  } as StateContextType;

  if (useUrl) {
    contextValue = {
      ...contextValue,
      ...useTokenAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else {
    contextValue = {
      ...contextValue,
      getToken: async (roomID, accessCode) => {
        const identity = accessCode;
        const roomName = roomID;
        const headers = new window.Headers();
        const endpoint = 'https://api.trakmd.com/token';
        const params = new window.URLSearchParams({ identity, roomName });

        return fetch(`${endpoint}?${params}`, { headers }).then(res => res.text());
      },
    };
  }
  const getToken: StateContextType['getToken'] = (room: any, name: any) => {
    setIsFetching(true);
    return contextValue
      .getToken(room, name)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return <StateContext.Provider value={{ ...contextValue, getToken }}>{props.children}</StateContext.Provider>;
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
