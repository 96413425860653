import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch, useParams } from 'react-router-dom';
import { ConnectOptions } from 'twilio-video';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import LoginPage from './components/LoginPage/LoginPage';
import ErrorPage from './components/ErrorPage/ErrorPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { VideoProvider } from './components/VideoProvider';
import rootReducer from './store/reducers/reducers';
import logger from 'redux-logger';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import WebSocketProvider, { WebSocketContext } from './components/socketProvider/socketProvider';
/**
 * Creating redux store
 */

const store = createStore(rootReducer, applyMiddleware(thunk));

const connectionOptions: ConnectOptions = {
  bandwidthProfile: {
    video: {
      mode: 'collaboration',
      renderDimensions: {
        high: { height: 1080, width: 1920 },
        standard: { height: 90, width: 160 },
        low: { height: 90, width: 160 },
      },
    },
  },
  dominantSpeaker: true,
  maxAudioBitrate: 12000,
  networkQuality: { local: 1, remote: 0 },
  preferredVideoCodecs: [{ codec: 'VP8', simulcast: false }],
};

const VideoApp = () => {
  const { error, setError } = useAppState();
  return (
    <UnsupportedBrowserWarning>
      <Provider store={store}>
        <WebSocketProvider>
          <VideoProvider options={connectionOptions} onError={setError}>
            <ErrorDialog dismissError={() => setError(null)} error={error} />
            <App />
          </VideoProvider>
        </WebSocketProvider>
      </Provider>
    </UnsupportedBrowserWarning>
  );
};

ReactDOM.render(
  <Router>
    <AppStateProvider>
      <Switch>
        <PrivateRoute exact path="/">
          <VideoApp />
        </PrivateRoute>
        <PrivateRoute exact path="/:roomhash/:accesscode">
          <VideoApp />
        </PrivateRoute>
        <Route path="/connection-error">
          <ErrorPage />
        </Route>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Redirect to="/" />
      </Switch>
    </AppStateProvider>
  </Router>,
  document.getElementById('root')
);
