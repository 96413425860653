import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';
import { api } from '../../_helpers/api';
const useStyles = makeStyles({
  container: {
    height: '100vh',
    background: '#fff',
  },
  twilioLogo: {
    width: '55%',
    display: 'block',
  },
  videoLogo: {
    width: '200px',
    padding: '0 0 2.1em',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '2em',
    background: 'white',
    color: 'black',
    maxWidth: '300px',
    width: '100%',
  },
  button: {
    color: 'white',
    background: '#00447c',
    margin: '0.8em 0 0.7em',
    textTransform: 'none',
    width: '100%',
    '& :hover': {
      color: 'white',
      background: '#00447c',
    },
  },
  inputs: {
    marginBottom: '20px',
    width: '100%',
    ':after ': {
      display: 'none',
    },
  },
  formSt: {
    width: '100%',
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
});

const theme = createMuiTheme({
  palette: {
    type: 'light',
  },
});

export default function ConsultEnded() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const [passcode, setPasscode] = useState('');
  const [consultid, setConsultID] = useState('');
  const [authError, setAuthError] = useState<Error | null>(null);

  const backTo = () => {
    window.location.href = api.SYSTEM_API + 'user';
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container justify="center" alignItems="center" className={classes.container}>
        <Paper className={classes.paper} elevation={0}>
          <img
            className={classes.videoLogo}
            src="https://trakmd.com/doctor/public/images/trak_md_500.png"
            alt="Video Logo"
          ></img>
          <Grid container alignItems="center" direction="column">
            <h1 className="consult_end_title">Consultation has been ended</h1>
            <Button variant="contained" className={classes.button} onClick={backTo} type="submit">
              Back to dashboard
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </ThemeProvider>
  );
}
