import React from 'react';
import Participant from '../Participant/Participant';
import ParticipantNoVideo from '../Participant/ParticipantNoVideo';
import { styled } from '@material-ui/core/styles';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useMainSpeaker from '../../hooks/useMainSpeaker/useMainSpeaker';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import Tooltip from '@material-ui/core/Tooltip';
const Container = styled('aside')(({ theme }) => ({
  position: 'absolute',
  top: 'auto',
  bottom: '0',
  right: `20px`,
  maxWidth: '100%',
  zIndex: 999,
  padding: '0.5em',
  overflowY: 'auto',
}));

export default function ParticipantStrip() {
  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const mainParticipant = useMainSpeaker();
  return (
    <Container className="participants-strip">
      {mainParticipant !== localParticipant && (
        <Participant
          participant={localParticipant}
          isSelected={selectedParticipant === localParticipant}
          onClick={() => setSelectedParticipant(localParticipant)}
        />
      )}
      {participants.map(participant => {
        if (mainParticipant !== participant) {
          return (
            <Participant
              key={participant.sid}
              participant={participant}
              isSelected={selectedParticipant === participant}
              onClick={() => setSelectedParticipant(participant)}
            />
          );
        } else {
          return (
            <ParticipantNoVideo
              key={participant.sid}
              participant={participant}
              isSelected={selectedParticipant === participant}
              onClick={() => setSelectedParticipant(participant)}
            />
          );
        }
      })}
    </Container>
  );
}

/*<Participant
        participant={localParticipant}
        isSelected={selectedParticipant === localParticipant}
        onClick={() => setSelectedParticipant(localParticipant)}
      />*/
