import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { LocalParticipant, RemoteParticipant, RemoteVideoTrack, LocalVideoTrack } from 'twilio-video';
import { globals } from '../../_helpers/globals';
import BandwidthWarning from '../BandwidthWarning/BandwidthWarning';
import MicOff from '@material-ui/icons/MicOff';
import NetworkQualityLevel from '../NewtorkQualityLevel/NetworkQualityLevel';
import ParticipantConnectionIndicator from './ParticipantConnectionIndicator/ParticipantConnectionIndicator';
import PinIcon from './PinIcon/PinIcon';
import ScreenShare from '@material-ui/icons/ScreenShare';
import VideocamOff from '@material-ui/icons/VideocamOff';
import Tooltip from '@material-ui/core/Tooltip';
import useParticipantNetworkQualityLevel from '../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel';
import usePublications from '../../hooks/usePublications/usePublications';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublicationIsTrackEnabled from '../../hooks/usePublicationIsTrackEnabled/usePublicationIsTrackEnabled';
import useTrack from '../../hooks/useTrack/useTrack';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      alignItems: 'center',
      maxWidth: '150px',
      minWidth: '100px',
      background: 'transparent',
      width: '100%',
      minHeight: '100px',
      maxHeight: '100px',
      overflow: 'hidden',
      cursor: 'pointer',
      float: 'right',
      marginLeft: '10px',
      marginBottom: '0',
      '& video': {
        filter: 'none',
        borderRadius: '5px',
        overflow: 'hidden',
        boxSizing: 'border-box',
        border: 'solid 2px #ffffff14',
      },
      '& svg': {
        fontSize: '15px !important',
        stroke: 'white',
        strokeWidth: '0.8px',
        marginLeft: '5px',
      },
    },
    isVideoSwitchedOff: {
      '& video': {
        filter: 'blur(4px) grayscale(1) brightness(0.5)',
      },
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      padding: '0',
      width: '100%',
      background: 'transparent',
    },
    hideVideo: {
      background: '#383838',
      borderRadius: '10px',
      color: '#fff',
    },
    identity: {
      background: 'transparent',
      color: '#fff',
      padding: '5px',
      margin: '10px',
      borderRadius: '30px',
      fontWeight: 'normal',
      fontSize: '12px',
      display: 'flex',
      alignItems: 'center',
    },
    infoRow: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    participantIcons: {
      position: 'relative',
      top: '13px',
      right: '5px',
    },
  })
);

interface ParticipantInfoProps {
  participant: LocalParticipant | RemoteParticipant;
  children: React.ReactNode;
  onClick: () => void;
  isSelected: boolean;
}

export default function ParticipantInfo({ participant, onClick, isSelected, children }: ParticipantInfoProps) {
  const publications = usePublications(participant);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(p => p.trackName.toLowerCase().includes('camera'));

  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);
  const isAudioEnabled = usePublicationIsTrackEnabled(audioPublication);
  const isVideoEnabled = Boolean(videoPublication);
  const isScreenShareEnabled = publications.find(p => p.trackName.toLowerCase().includes('screen'));

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const classes = useStyles();
  //{participant.identity}

  if (!participant) {
    return <></>;
  }

  return (
    <Tooltip title={participant.identity} placement="top">
      <div
        className={clsx(classes.container + ' participant-item', {
          [classes.isVideoSwitchedOff]: isVideoSwitchedOff,
        })}
        onClick={onClick}
        data-cy-participant={participant.identity}
      >
        <div className={clsx(classes.infoContainer, { [classes.hideVideo]: !isVideoEnabled })}>
          <div className={classes.infoRow}>
            <h4 className={classes.identity}>
              <ParticipantConnectionIndicator participant={participant} />
            </h4>
            {!isVideoEnabled && <h3 className="initialName">{globals.getInitials(participant.identity)}</h3>}
            <div className={classes.participantIcons}>
              {!isAudioEnabled && <MicOff data-cy-audio-mute-icon />}
              {!isVideoEnabled && <VideocamOff />}
              {isScreenShareEnabled && <ScreenShare />}
              {isSelected && <PinIcon />}
              <NetworkQualityLevel qualityLevel={networkQualityLevel} />
            </div>
          </div>
        </div>
        {isVideoSwitchedOff && <BandwidthWarning />}
        {children}
      </div>
    </Tooltip>
  );
}
