import { combineReducers } from 'redux';
import {
  CREATE_TOAST,
  ADD_MESSAGE,
  GET_MESSAGES,
  ADD_MESSAGES,
  ADD_ROOM,
  GET_ROOMS,
  ADD_USERS,
  ADD_USER,
  SET_ROOM_DATA,
  SET_OFFLINE,
  SET_ONLINE,
  SEND_MESSAGE,
  CHAT_OPEN,
  SET_SOCKET,
} from '../Actions/Actions';
const TOAST_INITIAL = {
  isOpen: false,
  message: '',
};

const CALL_INITIAL = {
  isEndCall: false,
};

const SOCKET_INITIAL = null;

const ROOMS_INITIAL = {
  socket: null,
  chatopen: false,
  unread: 0,
  incall: false,
  connecting: false,
  medialoaded: false,
  audioloaded: false,
  socketconnected: false,
  videoroom: null,
  consult_ended: false,
  pending_messages: 0,
  isredirecting: false,
  main_speaker: {
    identity: '',
    sid: null,
  },
  users: [
    {
      access_id: null,
      access_hash: '',
      access_code: '',
      room_id: null,
      access_added: '',
      is_host: null,
      user_id: null,
      user_type: '',
      display_name: '',
      logged_in: '',
      banned: '',
      user_ip: null,
      room_type: '',
      is_active: null,
    },
  ],
  messages: [
    {
      id: null,
      fromuser: null,
      fromuser_type: '',
      touser: null,
      touser_type: '',
      message: '',
      drive: '',
      drive_data: '',
      photo: '',
      file_data: '',
      isread: '',
      datetime: '',
      ip_address: '',
    },
  ],
};

const toastReducer = (state = TOAST_INITIAL, action) => {
  switch (action.type) {
    case CREATE_TOAST:
      return {
        isOpen: action.isOpen,
        message: action.message,
      };
    default:
      return state;
  }
};

const callReducer = (state = CALL_INITIAL, action) => {
  switch (action.type) {
    case 'ASK_PRE_END_CALL':
      return {
        ...state,
        isEndCall: action.isEndCall,
      };
    default:
      return state;
  }
};

const roomReducer = (state = ROOMS_INITIAL, action) => {
  switch (action.type) {
    case SET_SOCKET:
      return {
        ...state,
        socket: action.socket,
      };
      break;
    case SET_ROOM_DATA:
      return {
        ...state,
        messages: action.messages,
        users: action.users,
      };
      break;
    case ADD_MESSAGES:
      return {
        ...state,
        messages: action.messages,
      };
      break;
    case SEND_MESSAGE:
      break;
    case ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.message],
      };
      break;
    case ADD_USERS:
      return {
        ...state,
        messages: action.messages,
      };
      break;
    case ADD_USER:
      return {
        ...state,
        users: [...state.users, action.user],
      };
      break;
    case SET_OFFLINE:
      return {
        ...state,
        users: state.users.map(user => {
          console.log(user + ' ' + action.user.user_id);
          if (user.user_id === action.user.user_id) {
            console.log('user matched for offline');
            return { ...user, is_active: 0 };
          }
          console.log('returning nothing for offline');
          return user;
        }),
      };
      break;
    case SET_ONLINE:
      return {
        ...state,
        users: state.users.map(user => {
          if (user.user_id === action.user.user_id) {
            console.log('user matched for online');
            return { ...user, is_active: 1, display_name: action.user.name };
          }
          console.log(user);
          return user;
        }),
      };
      break;
    case CHAT_OPEN:
      return {
        ...state,
        chatopen: state.chatopen ? false : true,
      };
      break;
    case 'UNREAD_MESSAGE':
      return {
        ...state,
        unread: state.unread + 1,
      };
      break;
    case 'READ_ALL_MESSAGE':
      return {
        ...state,
        unread: 0,
      };
      break;
    case 'SET_IN_CALL':
      return {
        ...state,
        incall: action.incall,
      };
      break;
    case 'SET_CONNECTING':
      return {
        ...state,
        connecting: action.connecting,
      };
      break;
    case 'MEDIA_LOADED':
      return {
        ...state,
        medialoaded: action.medialoaded,
      };
      break;
    case 'AUDIO_LOADED':
      return {
        ...state,
        audioloaded: action.audioloaded,
      };
      break;
    case 'CONNECTED_TO_SOCKET':
      return {
        ...state,
        socketconnected: action.socketconnected,
      };
      break;
    case 'VIDEO_ROOM':
      return {
        ...state,
        videoroom: action.videoroom,
      };
      break;
    case 'CONSULT_ENDED':
      return {
        ...state,
        consult_ended: action.consult_ended,
      };
      break;
    case 'ADD_PENDING_MESSAGE':
      return {
        ...state,
        pending_messages: state.pending_messages + 1,
      };
      break;
    case 'REMOVE_PENDING_MESSAGE':
      return {
        ...state,
        pending_messages: state.pending_messages - 1,
      };
      break;
    case 'SET_REDIRECTING':
      return {
        ...state,
        isredirecting: action.isredirecting,
      };
      break;
    case 'MAIN_SPEAKER':
      return {
        ...state,
        main_speaker: action.speaker,
      };
      break;
    default:
      return state;
  }
};

const appReducer = combineReducers({
  /* your app’s top-level reducers */
  toast: toastReducer,
  room: roomReducer,
  call: callReducer,
});

const rootReducer = (state, action) => {
  // state = undefined;
  return appReducer(state, action);
};

export default rootReducer;
