import React, { useState } from 'react';
import ParticipantStrip from '../ParticipantStrip/ParticipantStrip';
import GridParticipantStrip from '../ParticipantStrip/GridParticipantStrip';
import { styled } from '@material-ui/core/styles';
import Participants from './components/Participants';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { useSelector, useDispatch } from 'react-redux';
import ChatBox from './components/ChatBox';
const Container = styled('div')({
  position: 'relative',
  height: '100%',
  backgroundColor: '#fff',
  overflow: 'hidden',
});

export default function Room() {
  const [isGrid, setIsGrid] = useState(false);
  const { chatopen, unread } = useSelector(state => state.room);
  const dispatch = useDispatch();
  const chatToggle = () => {
    if (!chatopen) {
      dispatch({
        type: 'READ_ALL_MESSAGE',
      });
    }
    dispatch({
      type: 'CHAT_OPEN',
    });
  };
  return (
    <Container>
      <div className="part">
        <div className="heading">
          <span className="head_text">Participants</span>
          <a href="#" onClick={chatToggle} className="closechatbutton">
            <ArrowBackIosRoundedIcon />
            &nbsp;Back to call
          </a>
        </div>
        <Participants />
      </div>
      <div className="chatbox">
        <div className="heading">
          <span className="head_text">Chat</span>
        </div>
        <ChatBox />
      </div>
    </Container>
  );
}
