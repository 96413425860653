import React, { useRef, useEffect } from 'react';
import { IVideoTrack } from '../../types';
import { styled } from '@material-ui/core/styles';
import { Track } from 'twilio-video';

const Video = styled('video')({
  width: '100%',
  maxHeight: '100%',
  height: '100%',
  objectFit: 'contain',

  boxSizing: 'border-box',
});

interface VideoTrackProps {
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
  imagesize: string;
  publicationType: string;
}

export default function VideoTrack({ track, isLocal, priority, imagesize, publicationType }: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!);

  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }
    track.attach(el);
    return () => {
      track.detach(el);
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  // The local video track is mirrored.
  //let style = isLocal ? { transform: 'scaleX(-1)', maxHeight: '100%' } : { maxHeight: '100%' };
  let style = { transform: 'scaleX(-1)', maxHeight: '100%' };

  if (publicationType == 'screen') {
    style = { transform: 'scaleX(1)', maxHeight: '100%' };
  }

  if (imagesize == 'small') {
    // style = isLocal ? { transform: 'scaleX(-1)', maxHeight: '100px' } : { maxHeight: '100px' };
    style = { transform: 'scaleX(-1)', maxHeight: '100px' };
  }

  return <Video ref={ref} style={style} />;
}
