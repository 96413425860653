import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useAppState } from '../../../state';
import CallEnd from '@material-ui/icons/CallEnd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch } from 'react-redux';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);

export default function EndCallButton() {
  const classes = useStyles();
  const { user } = useAppState();
  const { room } = useVideoContext();
  const dispatch = useDispatch();

  const handleEndCall = () => {
    const host = user?.is_host ? user.is_host.toString() : '0';
    if (host == '1') {
      dispatch({
        type: 'ASK_PRE_END_CALL',
        isEndCall: true,
      });
    } else {
      room.disconnect();
    }
  };

  return (
    <Tooltip title={'End Call'} onClick={() => handleEndCall()} placement="top" PopperProps={{ disablePortal: true }}>
      <div className="UseCall">
        <div className="call_act_button end">
          <CallEnd />
        </div>
      </div>
    </Tooltip>
  );
}
