import { useCallback, useState, useEffect } from 'react';
let timeint;
export default function useTimer() {
  const [limit, setLimit] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [currentTimeString, setCurrentTimeString] = useState('');

  useEffect(() => {
    if (limit) {
      timeint = setInterval(() => {
        setCurrentTime(currentTime => currentTime + 1);
        setCurrentTimeString(getFormat(currentTime));
      }, 1000);
    }
    return () => clearTimer();
  }, [limit, currentTime]);

  const clearTimer = () => {
    clearInterval(timeint);
  };

  const timerInterval = () => {};

  const setTimer = seconds => {
    setLimit(seconds);
    setCurrentTime(0);
  };

  const getFormat = timeInSeconds => {
    let pad = function(num, size) {
        return ('000' + num).slice(size * -1);
      },
      time = parseInt(parseFloat(timeInSeconds).toFixed(3)),
      hours = Math.floor(time / 60 / 60),
      minutes = Math.floor(time / 60) % 60,
      seconds = Math.floor(time - minutes * 60),
      milliseconds = time.toString().slice(-3);
    if (pad(hours, 2) != '00') {
      return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2);
    }
    if (pad(hours, 2) == '00' && pad(minutes, 2) != '00') {
      return pad(minutes, 2) + ':' + pad(seconds, 2);
    }

    if (pad(hours, 2) == '00' && pad(minutes, 2) == '00') {
      return pad(seconds, 2);
    }
    return '00:00';
  };

  return { getFormat, setTimer, clearTimer, currentTime, currentTimeString };
}
