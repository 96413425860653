import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { json } from 'express';
import { stringify } from 'querystring';
import { api } from '../../_helpers/api';

export function getUrlToken() {
  const match = window.location.search.match(/token=(.*)&?/);
  const token = match ? match[1] : '';
  return token;
}

export function getCurrentToken() {
  const match = window.location.search.match(/token=(.*)&?/);
  return match ? match[1] : null;
}

export function getExistingToken() {
  const token = window.sessionStorage.getItem('passcode');
  return token ? token : null;
}

export function getUrlParams() {}

export function fetchToken(token: string) {
  return fetch(api.API_URL + `auth/verifytoken`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ token: token }),
  });
}

export function fetchConsultToken(consult_id: string, access_code: string) {
  return fetch(api.API_URL + `auth/verify`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      consult_id: consult_id,
      access_code: access_code,
    }),
  });
}

export function validateToken(consult_id: string, access_code: string, authToken: string) {
  return fetch(api.API_URL + `auth/verifyauth`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: 'Bearer ' + authToken,
    },
    body: JSON.stringify({
      consult_id: consult_id,
      access_code: access_code,
      authToken: authToken,
    }),
  });
}

export function getCallToken(roomID, name) {
  const identity = name;
  const roomName = roomID;
  const headers = new window.Headers();
  const endpoint = api.API_URL + 'token';
  const params = new window.URLSearchParams({ identity, roomName });
  return fetch(`${endpoint}?${params}`, { headers });
}

export function verifyToken(token: string) {
  return fetchToken(token).then(async res => {
    const jsonResponse = await res.json();
    if (res.status === 401) {
      return {
        username: '',
        room: '',
        consult_id: '',
        is_host: '',
        user_id: '',
        user_type: '',
        appointment_id: '',
        state_date: '',
        room_type: '',
        token: '',
        authToken: '',
        isValid: false,
        total_time: '',
        error: jsonResponse.error?.message,
      };
    }

    if (res.ok && jsonResponse.token) {
      return {
        username: jsonResponse.data.display_name,
        room: jsonResponse.data.room_hash,
        consult_id: jsonResponse.data.consult_id,
        access_code: jsonResponse.data.access_hash,
        is_host: jsonResponse.data.is_host,
        user_id: jsonResponse.data.user_id,
        user_type: jsonResponse.data.user_type,
        appointment_id: jsonResponse.data.appointment_id,
        start_date: jsonResponse.data.start_date,
        room_type: jsonResponse.data.room_type,
        token: jsonResponse.token,
        authToken: jsonResponse.authToken,
        total_time: jsonResponse.data.total_time,
        isValid: true,
      };
    }
  });
}

export function verifyDetails(consult_id: string, access_code) {
  return fetchConsultToken(consult_id, access_code).then(async res => {
    const jsonResponse = await res.json();
    if (!jsonResponse.response) {
      return {
        username: '',
        room: '',
        consult_id: '',
        is_host: '',
        user_id: '',
        user_type: '',
        appointment_id: '',
        state_date: '',
        room_type: '',
        token: '',
        authToken: '',
        isValid: false,
        total_time: '',
        error: jsonResponse.message,
      };
    }

    if (res.ok && jsonResponse.response) {
      return {
        username: jsonResponse.data.display_name,
        room: jsonResponse.data.room_hash,
        consult_id: jsonResponse.data.consult_id,
        access_code: jsonResponse.data.access_hash,
        is_host: jsonResponse.data.is_host,
        user_id: jsonResponse.data.user_id,
        user_type: jsonResponse.data.user_type,
        appointment_id: jsonResponse.data.appointment_id,
        start_date: jsonResponse.data.start_date,
        room_type: jsonResponse.data.room_type,
        token: jsonResponse.token,
        authToken: jsonResponse.authToken,
        total_time: jsonResponse.data.total_time,
        isValid: true,
      };
    }
  });
}

export function verifyValidData(consult_id: string, access_code: string, authToken: string) {
  return validateToken(consult_id, access_code, authToken).then(async res => {
    const jsonResponse = await res.json();
    if (!jsonResponse.response) {
      return {
        isValid: false,
        error: jsonResponse.message,
      };
    }

    if (res.ok && jsonResponse.response) {
      return {
        isValid: true,
      };
    }
  });
}

export function getErrorMessage(message: string) {
  switch (message) {
    case 'passcode incorrect':
      return 'Passcode is incorrect';
    case 'passcode expired':
      return 'Passcode has expired';
    default:
      return message;
  }
}

export function setSignIn(verification) {
  window.sessionStorage.setItem('user', verification.username);
  window.sessionStorage.setItem('room', verification.room);
  window.sessionStorage.setItem('token', verification.token);
  window.sessionStorage.setItem('room_type', verification.room_type);
  window.sessionStorage.setItem('consult_id', verification.consult_id);
  window.sessionStorage.setItem('access_code', verification.access_code);
  window.sessionStorage.setItem('is_host', verification.is_host);
  window.sessionStorage.setItem('user_id', verification.user_id);
  window.sessionStorage.setItem('user_type', verification.user_type);
  window.sessionStorage.setItem('appointment_id', verification.appointment_id);
  window.sessionStorage.setItem('start_date', verification.start_date);
  window.sessionStorage.setItem('authToken', verification.authToken);
  window.sessionStorage.setItem('total_time', verification.total_time);
}

export function getSignedIn() {
  let user_data = {
    token: window.sessionStorage.getItem('token'),
    username: window.sessionStorage.getItem('user'),
    room: window.sessionStorage.getItem('room'),
    room_type: window.sessionStorage.getItem('room_type'),
    consult_id: window.sessionStorage.getItem('consult_id'),
    access_code: window.sessionStorage.getItem('access_code'),
    is_host: window.sessionStorage.getItem('is_host'),
    user_id: window.sessionStorage.getItem('user_id'),
    user_type: window.sessionStorage.getItem('user_type'),
    appointment_id: window.sessionStorage.getItem('appointment_id'),
    start_date: window.sessionStorage.getItem('start_date'),
    authToken: window.sessionStorage.getItem('authToken'),
    total_time: window.sessionStorage.getItem('total_time'),
  };
  return user_data;
}

export default function useTokenAuth() {
  const history = useHistory();
  const [user, setUser] = useState<{
    username: string;
    room: string;
    token: string;
    room_type: string;
    consult_id: string;
    access_code: string;
    is_host: number;
    user_id: string;
    appointment_id: string;
    authToken: string;
    user_type: string;
    start_date: string;
    total_time: number;
  } | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const getToken = useCallback(
    (roomid: string, name: string) => {
      return getCallToken(roomid, name)
        .then(res => res.json())
        .then(res => res.token as string);
    },
    [user]
  );

  useEffect(() => {
    const token = getUrlToken();
    if (token) {
      verifyToken(token)
        .then(verification => {
          if (verification?.isValid) {
            let {
              username,
              room,
              token,
              room_type,
              consult_id,
              access_code,
              is_host,
              user_id,
              user_type,
              appointment_id,
              start_date,
              authToken,
              total_time,
            } = verification;
            setUser({
              username,
              room,
              token,
              room_type,
              consult_id,
              access_code,
              is_host,
              user_id,
              user_type,
              appointment_id,
              start_date,
              authToken,
              total_time,
            } as any);
            setIsLoggedIn(true);
            setSignIn(verification);
            history.replace(window.location.pathname);
          } else {
            //throw new Error(verification?.error);
          }
        })
        .then(() => setIsAuthReady(true));
    } else {
      let room_hash = window.sessionStorage.getItem('room');
      let access_hash = window.sessionStorage.getItem('access_code');
      let authToken = window.sessionStorage.getItem('authToken');
      if (room_hash && access_hash && authToken) {
        verifyValidData(room_hash, access_hash, authToken)
          .then(verification => {
            if (verification?.isValid) {
              let {
                username,
                room,
                token,
                room_type,
                consult_id,
                access_code,
                is_host,
                user_id,
                user_type,
                appointment_id,
                start_date,
                authToken,
                total_time,
              } = getSignedIn();
              setUser({
                username,
                room,
                token,
                room_type,
                consult_id,
                access_code,
                is_host,
                user_id,
                user_type,
                appointment_id,
                start_date,
                authToken,
                total_time,
              } as any);
              setIsLoggedIn(true);
            } else {
              setIsAuthReady(true);
            }
          })
          .then(() => setIsAuthReady(true));
      } else {
        setIsAuthReady(true);
      }
    }
  }, [history]);

  const signIn = useCallback((consult_id: string, access_code) => {
    return verifyDetails(consult_id, access_code).then(verification => {
      if (verification?.isValid) {
        let {
          username,
          room,
          token,
          room_type,
          consult_id,
          access_code,
          is_host,
          user_id,
          user_type,
          appointment_id,
          start_date,
          authToken,
          total_time,
        } = verification;
        setUser({
          username,
          room,
          token,
          room_type,
          consult_id,
          access_code,
          is_host,
          user_id,
          user_type,
          appointment_id,
          start_date,
          authToken,
          total_time,
        } as any);
        setIsLoggedIn(true);
        setSignIn(verification);
        history.replace(window.location.pathname);
      } else {
        throw new Error(verification?.error);
      }
    });
  }, []);

  const signOut = useCallback(() => {
    let redirect_url = '';
    let is_host = window.sessionStorage.getItem('is_host');
    let room_type = window.sessionStorage.getItem('room_type');
    const host = is_host ? is_host.toString() : '0';
    if (room_type == 'consultation' && host == '1') {
      redirect_url = api.SYSTEM_API + 'doctor/appointment_list';
    } else {
      redirect_url = api.SYSTEM_API + 'user';
    }
    window.sessionStorage.removeItem('token');
    window.sessionStorage.removeItem('user');
    window.sessionStorage.removeItem('room');
    window.sessionStorage.removeItem('room_type');
    window.sessionStorage.removeItem('consult_id');
    window.sessionStorage.removeItem('access_code');
    window.sessionStorage.removeItem('is_host');
    window.sessionStorage.removeItem('user_id');
    window.sessionStorage.removeItem('user_type');
    window.sessionStorage.removeItem('appointment_id');
    window.sessionStorage.removeItem('start_date');
    window.sessionStorage.removeItem('authToken');
    window.sessionStorage.removeItem('total_time');
    if (redirect_url) {
      window.location.href = redirect_url;
      return Promise.resolve();
    } else {
      setUser(null);
      setIsLoggedIn(false);
    }
    return Promise.resolve();
  }, []);

  return { user, isAuthReady, getToken, signIn, signOut };
}
