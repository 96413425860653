import React, { useEffect,useContext,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Select from '@material-ui/core/Select';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { WebSocketContext } from '../../../components/socketProvider/socketProvider';
import useApiCall from '../../../hooks/useApiCall/useApiCall';
import { useAppState } from '../../../state';
import { api } from '../../../_helpers/api';
import { createToast } from '../../../store/Actions/Actions';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width:'100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function EndConsult() {
  const classes = useStyles();
  const { isEndCall } = useSelector(state => state.call);
  const ws = useContext(WebSocketContext);
  const [handingOver,setHandingOver] = useState(false);
  const [pracs,setPracs] = useState(null);
  const [selectedPrac,setSelectedPrac] = useState(''); 
  const dispatch = useDispatch();
  const { room } = useVideoContext();
  const { user } = useAppState();
  const { updateAppStatus } = useApiCall();

  useEffect(() => {
    getPracData();
  }, []);
  
  const handleClose = () => {
    dispatch({
      type: 'ASK_PRE_END_CALL',
      isEndCall: false,
    });
  };

  const getPracData = () => {
    const formData = new FormData();
    formData.append('doctor_id',user?.user_id);
    formData.append('doctor_type',user?.user_type);
    fetch(api.API_URL + `practitioners/get`, {
      method: 'POST',
      headers: {
        //'content-type': 'application/json',
        authorization: 'Bearer ' + user?.authToken,
      },
      body: formData,
    })
      .then(response => response.json())
      .then(res => {
        if(res.response){
          setPracs(res.data);
        }
      });
  }

  const selectPrac = (evt) => {
    console.log(evt);
    setSelectedPrac(evt.target.value);
  }

  const handover = () => {
    if(selectedPrac){
      const formData = new FormData();
    formData.append('practitioner',selectedPrac);
    formData.append('appointment_id',user?.appointment_id);
    fetch(api.API_URL + `consultation/handover`, {
      method: 'POST',
      headers: {
        //'content-type': 'application/json',
        authorization: 'Bearer ' + user?.authToken,
      },
      body: formData,
    })
      .then(response => response.json())
      .then(res => {
        if(res.response){
          directBack();
        } else {
          dispatch(createToast(res.message));
        }
      });
    } else {
      directBack();
    }
  };



  const directBack = () => {
    // updateAppStatus(4);
    ws.redirectUser(user?.room);
    ws.disconnectMe();
    dispatch({
      type: 'ASK_PRE_END_CALL',
      isEndCall: false,
    });
    setTimeout(() => {
      window.location.href = api.SYSTEM_API + 'doctor/appointment_list';
    }, 1000);
  }

  const endConsult = () => {
    ws.endConsultation(user?.room);
    //sendSeen(user?.room);
    room.disconnect();
    window.location.href = api.SYSTEM_API + 'doctor/appointment_list';
  };

  const sendSeen = room => {
    fetch(api.API_URL + `consultation/update`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: 'Bearer ' + user?.authToken,
      },
      body: JSON.stringify({
        room: room,
        status: 2,
        appointment_id: user?.appointment_id,
      }),
    })
      .then(response => response.json())
      .then(res => {
        window.location.href = api.SYSTEM_API + 'doctor/appointment_list';
      });
  };

  return (
    <div>
      <Dialog
        open={isEndCall}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {handingOver ? 
        <><DialogTitle id="alert-dialog-title">{'Select Practitoner'}</DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
            Please select practitoner you want to handover to Or leave empty to Normal Handover
          </DialogContentText>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Practitioner</InputLabel>
            <Select
              labelId="simple-select-label"
              id="simple-select"
              value={selectedPrac}
              onChange={(evt) => selectPrac(evt)}
            >
              {pracs && pracs.map((val) => {
                return <MenuItem value={val.id+'_'+val.utype.toLowerCase()}>{val.full_name}</MenuItem>
                
                })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHandingOver(false)} color="default">
            Back
          </Button>
          <Button onClick={handover} color="primary" autoFocus>
            Handover
          </Button>
        </DialogActions></>
        
        :<><DialogTitle id="alert-dialog-title">{'Close Consultation'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you ending this consultation or handing it over?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHandingOver(true)} color="primary">
            Handover
          </Button>
          <Button onClick={endConsult} color="primary" autoFocus>
            End Consultation
          </Button>
        </DialogActions></>}
      </Dialog>
    </div>
  );
}
