import { useContext, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAppState } from '../../state';
//import useSocketConnection from '../useSocketConnection/useSocketConnection';
import { WebSocketContext } from '../../components/socketProvider/socketProvider';
import { api } from '../../_helpers/api';
import axios from 'axios';
export default function useChat() {
  const { user } = useAppState();
  const ws = useContext(WebSocketContext);
  const { toast } = useSelector(state => state);
  const dispatch = useDispatch();

  const createMessage = message => {
    dispatch({
      type: 'ADD_PENDING_MESSAGE',
    });
    fetch(api.API_URL + `message/create`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: 'Bearer ' + user?.authToken,
      },
      body: JSON.stringify({
        user_id: user?.user_id,
        user_type: user?.user_type,
        room_hash: user?.room,
        message: message,
      }),
    })
      .then(response => response.json())
      .then(res => {
        console.log(res);
        console.log(ws);
        ws.sendMessage(user?.room, res.message_data);
        dispatch({
          type: 'REMOVE_PENDING_MESSAGE',
        });
        dispatch({
          type: 'ADD_MESSAGE',
          message: res.message_data,
        });
      });
  };

  const sendFile = filedata => {
    dispatch({
      type: 'ADD_PENDING_MESSAGE',
    });
    const user_id = user?.user_id ? user.user_id : '';
    const user_type = user?.user_type ? user.user_type : '';
    const room_hash = user?.room ? user.room : '';
    const data = new FormData();
    data.append('msg_file', filedata);
    data.append('user_id', user_id);
    data.append('user_type', user_type);
    data.append('room_hash', room_hash);
    data.append('action', 'addMessageFile');
    axios.post(api.SYSTEM_API + 'ajax/managerequest.php', data, {}).then(res => {
      // then print response status
      if (res.data.response) {
        ws.sendMessage(user?.room, res.data.message_data);
        dispatch({
          type: 'REMOVE_PENDING_MESSAGE',
        });
        dispatch({
          type: 'ADD_MESSAGE',
          message: res.data.message_data,
        });
      }
    });
  };

  return { sendFile, createMessage };
}
