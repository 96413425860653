import { useState, useCallback, useRef } from 'react';

export default function useSettingsToggle() {
  const [isOpen, setisOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    !isOpen ? setisOpen(true) : setisOpen(false);
  }, [isOpen, setisOpen]);

  return [isOpen, toggleOpen] as const;
}
