import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import EndCallButton from './EndCallButton/EndCallButton';
import ToggleAudioButton from './ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from './ToggleVideoButton/ToggleVideoButton';
import MessageChat from './MessageChat/MessageChat';
import AttachmentToggle from './AttachmentToggle/AttachmentToggle';
import FullScreen from './FullScreen/FullScreen';
import ToggleScreenShareButton from './ToogleScreenShareButton/ToggleScreenShareButton';

import useIsUserActive from './useIsUserActive/useIsUserActive';
import useRoomState from '../../hooks/useRoomState/useRoomState';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      position: 'absolute',
      left: 0,
      right: 0,
      transform: 'translate(0, 60px)',
      bottom: '60px',
      justifyContent: 'center',
      zIndex: 99,
      transition: 'opacity 1.2s, transform 1.2s, visibility 0s 1.2s',
      opacity: 0,
      visibility: 'hidden',
      padding: '0 0',
      '&.showControls, &:hover': {
        transition: 'opacity 0.6s, transform 0.6s, visibility 0s',
        opacity: 1,
        visibility: 'visible',
        transform: 'translate(0, 0px)',
      },
    },
    toolBox: {
      left: 0,
      width: '100%',
      position: 'absolute',
      bottom: 0,
      top: 'auto',
      height: 'auto',
      zIndex: 99,
      '&.showControls, &:hover .showControls': {
        transition: 'opacity 0.6s, transform 0.6s, visibility 0s',
        opacity: 1,
        visibility: 'visible',
        transform: 'translate(0, 0)',
      },
    },
    centerAct: {
      display: 'flex',
      borderRadius: '3px',
      overflow: 'hidden',
      justifyContent: 'center',
    },
    leftAct: {
      display: 'flex',
      paddingLeft: '20px',
    },
    rightAct: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: '20px',
    },
  })
);

export default function Controls() {
  const classes = useStyles();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const isUserActive = useIsUserActive();
  const showControls = isUserActive || roomState === 'disconnected';

  return (
    <div className={classes.toolBox}>
      <div className={clsx(classes.container, { showControls })}>
        <div className={classes.leftAct}></div>
        <div className={classes.centerAct}>
          <MessageChat />
          <ToggleAudioButton disabled={isReconnecting} />
          <ToggleVideoButton disabled={isReconnecting} />
          {roomState !== 'disconnected' && (
            <>
              <ToggleScreenShareButton disabled={isReconnecting} />
              <FullScreen />
              <EndCallButton />
            </>
          )}
        </div>
        <div className={classes.rightAct}></div>
      </div>
    </div>
  );
}
