import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
export const CREATE_TOAST = 'CREATE_TOAST';
export const ADD_ROOM = 'ADD_ROOM';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_ROOMS = 'GET_ROOMS';
export const ADD_MESSAGES = 'ADD_MESSAGES';
export const ADD_USERS = 'ADD_USERS';
export const ADD_USER = 'ADD_USER';
export const SET_ROOM_DATA = 'SET_ROOM_DATA';
export const SET_OFFLINE = 'SET_OFFLINE';
export const SET_ONLINE = 'SET_ONLINE';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const CHAT_OPEN = 'CHAT_OPEN';
export const SET_SOCKET = 'SET_SOCKET';
export function createToast(message) {
  return async dispatch => {
    dispatch({
      type: CREATE_TOAST,
      isOpen: true,
      message: message,
    });
  };
}

export function closeToast() {
  return async dispatch => {
    dispatch({
      type: CREATE_TOAST,
      isOpen: false,
      message: '',
    });
  };
}

export function connectTocall(token) {
  return async (dispatch, getState) => {
    console.log('conneting to call called');
    const { isConnecting, connect } = useVideoContext();
    const { room } = getState();
    const { audioloaded, medialoaded } = room;
    if (token && audioloaded && medialoaded) {
      connect(token);
      dispatch({
        type: 'SET_IN_CALL',
        incall: true,
      });
    }
  };
}
