import React, { PropsWithChildren, useState } from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { TwilioError } from 'twilio-video';
import useSettingsToggle from '../../../hooks/useSettingsToggle/useSettingsToggle';

function SettingDialog() {
  const [isOpen, toggleOpen] = useSettingsToggle();
  console.log(isOpen);
  const body = <div></div>;

  return (
    <Modal
      open={isOpen}
      onClose={toggleOpen}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
}

export default SettingDialog;
