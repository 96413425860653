import React from 'react';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../VideoTrack/VideoTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { makeStyles } from '@material-ui/core/styles';
import { useAppState } from '../../state';
import { globals } from '../../_helpers/globals';
const useStyles = makeStyles({
  defaultRender: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '150px',
    height: '150px',
    color: '#9c9c9c',
    background: '#ffffff',
    margin: 'auto',
    borderRadius: '50%',
    border: 'solid 2px #eee',
    '& svg': {
      fontSize: '70px',
    },
  },
  initials: {
    fontSize: '50px',
  },
});
export function DefaultRenderLocal() {
  const { user } = useAppState();
  const classes = useStyles();
  return (
    <div className={classes.defaultRender}>
      {user != null && typeof user != 'undefined' && (
        <h3 className={classes.initials}>{globals.getInitials(user.username)}</h3>
      )}
    </div>
  );
}

export default function LocalVideoPreview() {
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find(track => track.name.includes('camera')) as LocalVideoTrack;

  return videoTrack ? (
    <VideoTrack track={videoTrack} isLocal imagesize="big" publicationType="camera" />
  ) : (
    <DefaultRenderLocal />
  );
}
