import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import CallEnd from '@material-ui/icons/CallEnd';
import Message from '@material-ui/icons/Message';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useSelector, useDispatch } from 'react-redux';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);

export default function MessageChat() {
  const classes = useStyles();
  const { room } = useVideoContext();

  const { chatopen, unread } = useSelector(state => state.room);
  const dispatch = useDispatch();
  const chatToggle = () => {
    if (!chatopen) {
      dispatch({
        type: 'READ_ALL_MESSAGE',
      });
    }
    dispatch({
      type: 'CHAT_OPEN',
    });
  };

  const chatClass = chatopen ? 'active' : '';
  const tooltip = chatopen ? 'Close Chat' : 'Open Chat';
  return (
    <Tooltip title={tooltip} placement="top">
      <div className="UseCall">
        <div onClick={chatToggle} className={'call_act_button ' + chatClass}>
          <Message /> {unread > 0 && <span className="count">{unread}</span>}
        </div>
      </div>
    </Tooltip>
  );
}
