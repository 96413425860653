import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import { useAppState } from '../../../state';
import Button from '@material-ui/core/Button';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
    exitButton: {
      borderRadius: '30px',
      marginRight: '10px',
    },
  })
);

export default function Logout(props: { disabled?: boolean }) {
  const classes = useStyles();
  const { signIn, user, isAuthReady, signOut } = useAppState();
  const Logout = () => {
    signOut?.();
  };
  const body = <div></div>;

  return (
    <div>
      <Tooltip title={'Exit'} placement="top">
        <Button
          className={'logoutbutton ' + classes.exitButton}
          type="submit"
          color="secondary"
          variant="contained"
          onClick={Logout}
        >
          <ExitToAppRoundedIcon />
          &nbsp;<span className="txt">Exit</span>
        </Button>
      </Tooltip>
    </div>
  );
}
