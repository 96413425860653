/*export const api = {
  API_URL: 'https://medex.trakmd.dev/api/',
  SYSTEM_API: 'https://medex.trakmd.dev/',
  SOCKET_URL: 'https://api.trakmd.dev/',
};*/

export const api = {
  API_URL: 'https://trakmd.com/api/',
  SYSTEM_API: 'https://trakmd.com/',
  SOCKET_URL: 'https://api.trakmd.com/',
};

/*export const api = {
  API_URL: 'http://trakmdapi.com/',
  SYSTEM_API: 'http://trakmd.net/',
  SOCKET_URL: 'http://localhost:8084/',
};*/
