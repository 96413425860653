import React, { useState, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import { useSelector, useDispatch } from 'react-redux';
import { useAppState } from '../../../state';
export default function Participants() {
  const [isGrid, setIsGrid] = useState(false);
  const { user, getToken, isFetching } = useAppState();
  const { users } = useSelector(state => state.room);
  const [currentuser, SetCurrentuser] = useState('');
  return (
    <div className="chatParticipants">
      {users.length > 0 ? (
        users.map(participant => (
          <div key={participant.user_id} className="participant">
            <div className="status">
              {participant.is_active ? (
                <FiberManualRecordIcon className="online" />
              ) : (
                <FiberManualRecordOutlinedIcon className="offline" />
              )}
            </div>
            <div className="det">
              {participant.display_name} {user && participant.user_id === user.user_id && '(You)'}
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
}
